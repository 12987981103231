import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const Outdoor = ({ data, active = true, ...props }) => {
  return (



    <div >
       <h6>Antenna informations</h6>

      <div className={"row col-md-6"}>
        <label>Azimuth : </label>
        <div className="col">
        {data.site.Azimuth_A? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div>: ''} 
        {data.site.Azimuth_B? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div>: ''} 
        {data.site.Azimuth_C? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Electrical tilt 900 : </label>
        <div className="col">
        {data.site.Electrical_900_A? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_900_A} </div>: ''} 
        {data.site.Electrical_900_B? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_900_B} </div>: ''} 
        {data.site.Electrical_900_C? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_900_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Electrical tilt 1800 : </label>
        <div className="col">
        {data.site.Electrical_1800_A? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_1800_A} </div>: ''} 
        {data.site.Electrical_1800_B? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_1800_B} </div>: ''} 
        {data.site.Electrical_1800_C? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_1800_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Electrical tilt 3G 2100 : </label>
        <div className="col">
        {data.site.Electrical_3G_2100_A? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_3G_2100_A} </div>: ''} 
        {data.site.Electrical_3G_2100_B? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_3G_2100_B} </div>: ''} 
        {data.site.Electrical_3G_2100_C? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_3G_2100_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Electrical tilt LTE : </label>
        <div className="col">
        {data.site.Electrical_LTE_A? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_LTE_A} </div>: ''} 
        {data.site.Electrical_LTE_B? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_LTE_B} </div>: ''} 
        {data.site.Electrical_LTE_C? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_LTE_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Mechanical tilt : </label>
        <div className="col">
        {data.site.Mechanical_A? <div className="row"> <b>Cell A </b>--> {data.site.Mechanical_A} </div>: ''} 
        {data.site.Mechanical_B? <div className="row"> <b>Cell B </b>--> {data.site.Mechanical_B} </div>: ''} 
        {data.site.Mechanical_C? <div className="row"> <b>Cell C </b>--> {data.site.Mechanical_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Antenna Type : </label>
        <div className="col">
        {data.site.Antenna_Type_A? <div className="row"> <b>Cell A </b>--> {data.site.Antenna_Type_A} </div>: ''} 
        {data.site.Antenna_Type_B? <div className="row"> <b>Cell B </b>--> {data.site.Antenna_Type_B} </div>: ''} 
        {data.site.Antenna_Type_C? <div className="row"> <b>Cell C </b>--> {data.site.Antenna_Type_C} </div>: ''} 
        </div>
      </div>

      <div className={"row col-md-6"}>
        <label>Antenna serial number : </label>
        <div className="col">
        {data.site.Antenna_serial_number_A? <div className="row"> <b>Cell A </b>--> {data.site.Antenna_serial_number_A} </div>: ''} 
        {data.site.Antenna_serial_number_B? <div className="row"> <b>Cell B </b>--> {data.site.Antenna_serial_number_B} </div>: ''} 
        {data.site.Antenna_serial_number_C? <div className="row"> <b>Cell C </b>--> {data.site.Antenna_serial_number_C} </div>: ''} 
        </div>
      </div>





      <div className={"test-photos-wrapper"}>
            <table className={"table"}>
              
              <tr>
                <th>CABLE ORGANISATION OF ANTENNA ON the MAST</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                   // key === 0
                   photo.index===0
                   &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 1
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 2
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>


              <tr>
                <th>Antenna azimuth (Area covered view).</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 3
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 4
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 5
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Antenna azimuth (Compass view)</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 6
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 7
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 8
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>electrical tilt 900 or print screen</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 9
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 10
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 11
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>electrical tilt 1800 or print screen</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 12
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 13
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 14
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>electrical tilt 3G 2100 or print screen</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 15
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 16
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 17
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>electrical tilt LTE or print screen</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 18
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                   photo.index=== 19
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 20
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>mechanical tilt using tilt meter</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 21
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 22
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 23
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>mechanical tilt mechanism</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                   photo.index=== 24
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 25
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 26
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Antenna type and serial number</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 27
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 28
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 29
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>INSTALLATION of the RRU (Fixation & LED STATUS) - CELL A -</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 30
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>


              <tr>
                <th>AERTHING of the RRU (RRU SIDE AND EARTHING BAR SIDE) - CELL A -</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 31
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>INSTALLATION of the RRU (Fixation & LED STATUS) - CELL b -</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 32
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>AERTHING of the RRU (RRU SIDE AND EARTHING BAR SIDE) - CELL B -</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 33
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>INSTALLATION of the RRU (Fixation & LED STATUS) - CELL c -</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 34
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>AERTHING of the RRU (RRU SIDE AND EARTHING BAR SIDE) - CELL C -</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                   photo.index=== 35
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>



              <tr>
                <th>Labels and sealing of jumpers (antenna side) </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 36
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 37
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 38
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Labels and sealing of jumpers (RRU side) </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 39
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 40
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                   photo.index=== 41
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>


              <tr>
                <th>Labels and sealing of jumpers (TMA side)  </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 42
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                   photo.index=== 43
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 44
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>


              <tr>
                <th>Labels and sealing between jumpers and feeders </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 45
                    &&
                    <div>
                      <h6>CELL A</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 46
                    &&
                    <div>
                      <h6>CELL B</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index=== 47
                    &&
                    <div>
                      <h6>CELL C</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                </div>
                )}
              </tr>

              
              <tr>
                <th>Earthing bars on the Mast, Tower and OUTDOOR cable ladder-</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 48
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>
              
              <tr>
                <th>Feeders grounding</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 49
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Installation of feeders, Optical Fiber  & DC CABLE  on the mast or tower</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 50
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Installation of feeders, Optical Fiber & DC CABLE  on the OUTDOOR cable ladder</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 51
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>ROXTEC OUTSIDE VIEW</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 52
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Mw view & Earthing. </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 53
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Mw sealing and label. </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 54
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>view of others installed equipments. </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 55
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>Unused Material or voucher of return </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 56
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>

              <tr>
                <th>outdoor general clean up </th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index=== 57
                    &&
                    <PhotoItem photo={photo} active={active} />
                  ))}
                </div>
                )}
              </tr>


              
            
            
            </table>
        
      </div>

      
    </div>
  );
};

export default Outdoor;
