import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";

const moment = require("moment");

const Recap = ({ data, active, ...props }) => {

  //console.log("hello here", data);

  return (
    <>

     {data.checks && data.checks.map((item, index) => (
       item.checks.map((check) => (
            <div className={"row col-md-6 border p-3 rounded m-1"}>
              {check.test_status === 'ok' && <FontAwesomeIcon icon={faCheck} color ="#5cb85c" size={"1x"} />}
              {check.test_status === 'nok' && <FontAwesomeIcon icon={faTimes} color ="#d9534f" size={"1x"} />}
              <label className="ml-2"> {check.test_content}: </label>
                {/* {check.test_status} */}
                Comment: {check.test_comment}
            </div>
       ))
     ))}
      {/* <div className={"row col-md-6"}>
        <label>Site access: </label>
        {data.site_access}
      </div>

      <div className={"row col-md-6"}>
        <label>Remarques : </label>
        {data.remarques_site_access}
      </div>

      <div className={"row col-md-6"}>
        <label>Goods Delivery: </label>
        {data.site.goods_delivery}
      </div>

      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_goods_delivery}
      </div>

      <div className={"row col-md-6"}>
        <label>Grounding System : </label>
        {data.site.grounding_system}
      </div>

      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_grounding_system}
      </div>

      <div className={"row col-md-6"}>
        <label>SBTS installation space: </label>
        {data.site.sbts_installation_space}
      </div>

      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_sbts_installation_space}
      </div>

      <div className={"row col-md-6"}>
        <label>Antenna space available: </label>
        {data.site.antenna_space_available}
      </div>

      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_antenna_space_available}
      </div>

      <div className={"row col-md-6"}>
        <label>RF space available: </label>
        {data.site.rf_space_available}
      </div>
      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_rf_space_available}
      </div>

      <div className={"row col-md-6"}>
        <label>Transmission Ready: </label>
        {data.site.transmission_ready}
      </div>


      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_transmission_ready}
      </div>

      <div className={"row col-md-6"}>
        <label>DC power system : </label>
        {data.site.dc_power_system}
      </div>


      <div className={"row col-md-6"}>
        <label>Remarques : </label>
        {data.site.remarques_dc_power_system}
      </div>

      <div className={"row col-md-6"}>
        <label>Site Adaptation : </label>
        {data.site.site_adaptation}
      </div>

      <div className={"row col-md-6"}>
        <label>Remarques: </label>
        {data.site.remarques_site_adaptation}
      </div>

      <div className={"row col-md-6"}>
        <label> Others : </label>
        {data.site.others}
      </div>

      <div className={"row col-md-6"}>
        <label> Remarques: </label>
        {data.site.remarques_others}
      </div> */}

      <div className={"row col-md-6"}>
        <label> Remark : </label>
        {data.site.remark}
      </div>



    </>
  );
};

export default Recap;
