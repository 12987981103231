import React, { Component } from "react";

const Footer = () => (
  <div className="footer-wrap">
    <div className="footer-content">
      <center>
        <small>
          Copyright © 2020 Witrack Rollout -{" "}
          <b>
            <a
              href="https://play.google.com/store/apps/details?id=com.witrack.rollout"
              target="_blank"
            >
              Mobile App
            </a>
          </b>
        </small>
      </center>
    </div>
  </div>
);

export default Footer;
