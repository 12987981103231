import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TaskListContainer from "../Containers/Delivery/TaskListContainer";
import CManagerList from "../Components/ClusterManager/CManagerList";
import ManagerList from "../Components/Manager/ManagerList";
import ManagerNew from "../Components/Manager/ManagerNew";
import ManagerDetail from "../Components/Manager/ManagerDetail";

import AgentNew from "../Components/Agent/AgentNew";
import AgentContainer from "../Containers/AgentContainer";
import Settings from "./../Components/Settings/Settings";
import LoginBox from "../Components/Login/LoginBox";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";
import CalendarPage from "../Components/Planning/CalendarPage";
import PlanningList from "../Components/Planning/PlanningList";
import AnomaliesContainer from "../Containers/Delivery/AnomaliesContainer";
import UserAccount from "../Components/User/UserAccount";
import CManagerNew from "../Components/ClusterManager/CManagerNew";
import AgentList from "../Components/Agent/AgentList/index";
import Manuals from "./../Components/Manuals/Manuals";

function mapStateToProps(state) {
  return { auth: state.session.auth };
}

class CommonRouter extends Component {
  render() {
    return (
      <Switch>




        {/**
         * Users routes
         */}
        <Route exact path='/clustermanagers' component={CManagerList} />
        <Route exact path='/clustermanagers/new' component={CManagerNew} />

        <Route exact path='/managers' component={ManagerList} />
        <Route exact path='/managers/new' component={ManagerNew} />
        <Route exact path='/managers/detail/:userid' component={ManagerDetail} />

        <Route exact path='/agents' component={AgentList} />
        <Route exact path='/agents/new' component={AgentNew} />
        <Route path='/agents/:userid' component={AgentContainer} />

        <Route path="/settings" component={Settings} />
        <Route path='/account' component={UserAccount} />
        <Route path='/delivery/anomalies' component={AnomaliesContainer} />
        <Route path='/delivery/planning' component={PlanningList} />
        <Route path='/delivery/calendar' component={CalendarPage} />
        <Route path='/dashboard' component={DashboardWrapper} />

        <Route path="/manuals" component={Manuals} />

      </Switch>
    );
  }
}

export default withRouter(connect(mapStateToProps)(CommonRouter));
