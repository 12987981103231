import React from "react";
import ClockIn from "./ClockIn";
import IGeneral from "./IGeneral";
import Electricity from "./Electricity";
import Power from "./Power";
import Batteries from "./Batteries";
import Shelter from "./Shelter";
import AC from "./AC";
import BSS from "./BSS";
import PDH from "./PDH";
import Alarms from "./Alarms";
import GE from "./GE";
import Exterieur from "./Exterieur";
import SpareParts from "./SpareParts";
import Anomalies from "./Anomalies";
import Photos from "./Photos";
import Security from "./Security";
import CoSite from "./CoSite";
import Sabotage from "./Sabotage";
import Pickup from "./Pickup";
import Drop from "./Drop";
import Access from "./Access";
import Data from "./Data";
import Material from "./Material";
import Alarm from "./Alarm";
import TiltBefore from "./TiltBefore";
import Recap from "./Recap";
import Drawing from "./Drawing";



const SSRTests = [
    {
        name: "Clock-in",
        key: "clockin",
        component: ClockIn,
    },
    {
        //name: "Accès au site",
        name: "Site Infromation",
        key: "access",
        component: Access,
    },
    // {
    //     name: "Données du site",
    //     key: "data",
    //     component: Data,
    // },
    // {
    //     name: "Matériel radio & trans existant",
    //     key: "material",
    //     component: Material,
    // },
    // {
    //     name: "Environnement & alarmes",
    //     key: "alarm",
    //     component: Alarm,
    // },
    {
        name: "Reportage Photos",
        key: "photos",
        component: Photos,
    },
    {
        name: "Drawing",
        key: "drawing",
        component: Drawing,
    },
    {
        name: "Recap",
        key: "recap",
        component: Recap,
    },
    // {
    //     name: "TiltBefore",
    //     key: "tiltbefore",
    //     component: TiltBefore,
    // },
    /*{
        name: "AC",
        key: "ac",
        component: AC,
    },
    {
        name: "Batteries",
        key: "batteries",
        component: Batteries,
    },
    {
        name: "Shelter",
        key: "shelter",
        component: Shelter,
    },
    {
        name: "BSS",
        key: "bss",
        component: BSS,
    },
    {
        name: "PDH-PAV-Redline",
        key: "pdh",
        component: PDH,
    },
    {
        name: "GE",
        key: "ge",
        component: GE,
    },
    {
        name: "Exterieure",
        key: "exterieur",
        component: Exterieur,
    },
    {
        name: "Alarms",
        key: "alarms",
        component: Alarms,
    },
    {
        name: "Spare Parts",
        key: "spareparts",
        component: SpareParts,
    },
    {
        name: "Anomalies",
        key: "anomalies",
        component: Anomalies,
    },
    {
        name: "Photos",
        key: "photos",
        component: Photos,
    },
    {
        name: "Securisation",
        key: "security",
        component: Security,
    },
    {
        name: "Sites colocalisé",
        key: "cosite",
        component: CoSite,
    },
    {
        name: "Sabotage",
        key: "sabotage",
        component: Sabotage,
    },*/
]

export default SSRTests;
