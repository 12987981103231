import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const Drawing = ({ data, active = true, ...props }) => {
  return (
    <div className={"test-photos-wrapper"}>
      {/*data.photos &&
        data.photos.length &&
        data.photos.map((photo, key) => (
          <div className={"test-photos-single"}>
            <PhotoItem photo={photo} active={true} />
          </div>
        ))*/}

      <table className={"table"}>
        <tr>
          {/* <th>Full front view of site from access road</th> */}
          {data.photos && (
          <div className={"test-photos-wrapper"}> 
            {data.photos.map((photo, key) => (
              // // key === 0
              // photo.index === 0
              // &&
              <PhotoItem photo={photo}  active={active} />
              
            ))}
          </div>
          )}
        </tr>

      </table>
    </div>
  );
};

export default Drawing;
