import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";
import {convertDMSToDD} from '../../../../Utils/Helpers'


const Photos = ({ data, active = true,  geo, acc, ...props }) => {
  // if(acc && acc.site && acc.site.lat && acc.site.lng){
  //   geo = {lat: !!(acc.site.lat % 1)  ? acc.site.lat : convertDMSToDD( acc.site.lat) , lng:  !!(acc.site.lng % 1)  ? acc.site.lng  : convertDMSToDD(acc.site.lng) }
  // }
  return (
    <div className={"test-photos-wrapper"}>
      {/*data.photos &&
        data.photos.length &&
        data.photos.map((photo, key) => (
          <div className={"test-photos-single"}>
            <PhotoItem photo={photo} active={true} />
          </div>
        ))*/}

      <table className={"table"}>
        <tr>
          <th>Full front view of site from access road</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}> 
            {data.photos.map((photo, key) => (
              // key === 0
              photo.index === 0
              &&
              <PhotoItem photo={photo}  active={active} geo={geo}/>
              
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Site access front view</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 1
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Site IDs Picture</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 2
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Lock status </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 3
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Security Fence status View 1 garde corps </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 4
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Security Fence status View 2 garde corps</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 5
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Site Layout from three/four points on top on tower</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 6
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Closed outdoor cabinets 1</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 7
              &&
              <PhotoItem photo={photo} active={active} geo={geo} />
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Closed outdoor cabinets 2</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 8
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Closed outdoor cabinets 3</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 9
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Open cabinet 1</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 10
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Open cabinet 2</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 11
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>  Open cabinet 3</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 12
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Power Modules</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 13
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Power cabinet controler (Main deplay)</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 14
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Batteries</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 15
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>TGBT Brand</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 16
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>TGBT interior layout</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 17
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> TGBT Default if exist</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 18
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Genset global</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 19
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Genset brand</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 20
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Genset information plate</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 21
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Air conditionel Brand</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 22
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Air conditionel outdoor units</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 23
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Air conditionel ATS</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 24
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Free space for Telco expansion (In/Out)</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 25
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Free space for Telco expansion (In/Out)</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 26
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Free space for Telco expansion (In/Out)</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 27
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>VSAT</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 28
              &&
              <PhotoItem photo={photo} active={active} geo={geo} />
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Solar Panel</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 29
              &&
              <PhotoItem photo={photo} active={active} geo={geo} />
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Millatary équipements</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 30
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Photo 4</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 31
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Photo 5</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 32
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Photo 6</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 33
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Mast Global view Sector 01</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 34
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Mast Global view Sector 02</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 35
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Mast Global view Sector 03</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 36
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Mast Lader</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 37
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 38
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 39
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 40
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 41
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 42
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation struss   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 43
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation struss   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 44
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation struss   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 45
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation struss   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 46
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation struss   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 47
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Fixation struss   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 48
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Tower lightning rod (tige parafoudre)   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 49
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Tower lightning cable   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 50
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Lightning inspection pit  </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 51
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Mast Default   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 52
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Mast Default </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 53
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>Mast Default   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 54
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>Sector 01   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 55
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Sector 02   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 56
              &&
              <PhotoItem photo={photo} active={active} geo={geo} />
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>Sector 03   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 57
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>Sector 04   </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 71
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>étiquette sector 1  </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 82
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>étiquette sector 2  </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 83
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>étiquette sector 3  </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 84
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>étiquette sector 4  </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 85
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th>Radio Remote Unit Sector 1  </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 58
              &&
              <PhotoItem photo={photo} active={active}geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Radio Remote Unit Sector 2 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 59
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Radio Remote Unit Sector 3 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 60
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> Radio Remote Unit Sector 4 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 81
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> étiquette Remote Unit sector 1 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 86
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> étiquette Remote Unit sector 2 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 87
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> étiquette Remote Unit sector 3 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 88
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>étiquette Remote Unit sector 4</th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 89
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 1 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 61
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th> MW Antenna 2 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 62
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 3 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 63
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 4 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 64
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 5 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 65
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th> MW Antenna 6 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 66
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th> MW Antenna 7 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 72
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th> MW Antenna 8 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 73
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        <tr>
          <th> MW Antenna 9 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 74
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 10 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 75
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 11 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 76
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 12 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 77
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 13 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 78
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> MW Antenna 14 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 79
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
        
        <tr>
          <th> MW Antenna 15 </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 80
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>  serrure endomage </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 67
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>  GPS Picture </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 68
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th> SNG ELECTRICITY CONSUMPTION METER (SERIAL NUMBER) </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 69
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>

        <tr>
          <th>SNG ELECTRICITY CONSUMPTION METER (UNDER COUNTING) </th>
          {data.photos && (
          <div className={"test-photos-wrapper"}>
            {data.photos.map((photo, key) => (
              photo.index === 70
              &&
              <PhotoItem photo={photo} active={active} geo={geo}/>
            ))}
          </div>
          )}
        </tr>
      
      </table>
    </div>
  );
};

export default Photos;
