import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faSpinner, faPlus , faCheckDouble, faTimes, faCheckCircle, faStickyNote, faReply, faCheckSquare} from '@fortawesome/free-solid-svg-icons';
import MoreInfoDialog, { Item } from '../../../Utils/MoreInfoDialog';
import moment from 'moment';
import CheckOrTime from './../components/CheckOrTime';
import Restricted from '../../../Utils/Restricted';
import api from '../../../Utils/Api';
import SSRTests from './Tests';
import RenderComponent from '../../../Utils/RenderComponent';
import Constants from '../../../Utils/Constants';
import ApiService from '../../../Utils/ApiService';
import { useSelector } from 'react-redux';
import NotesReturn from "./Tests/NotesReturn";


const TaskRow = ({ task, key, /*exportPM,*/ deleteTask, returnTask, closeTask, acceptTask, rejectTask, showNewReplan, addDataTask, ...props }) => {

  // const i = key;
  
  const { session } = useSelector(state => ({ session: state.session }));
  const [activeTest, selectTest] = useState("clockin")
  const [showDetail, setShowDetail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const exportForm = useRef(null);
  const detailInput = useRef(null);
  //useEffect(data, [])
  const selectTask = (show = true) => {

    if (!showDetail) {
      setLoading(true);
      ApiService.fetchSSRTaskDetail(task.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
          }
          setLoading(false);
        })
    }
    setShowDetail(!showDetail)
  }

  const exportPM = (anomalies) => {

    setLoading(true);
    if (anomalies) {
      exportForm.current.action = Constants.apiEndPoints.EXPORT_SSR_TASK_ANOMALIES(task.task_id);
    } else {
      exportForm.current.action = Constants.apiEndPoints.EXPORT_SSR_TASK(task.task_id);
    }

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
            detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        })
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  }

  const exportPMXLSX = (anomalies) => {

    setLoading(true);
    
    exportForm.current.action = Constants.apiEndPoints.EXPORTPDF_SSR_TASK(task.task_id);
    

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
            detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        })
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  }
  onchange = (id, test, field, value , isTable = false , isPower = false , att = '') => { 
   // const value = e.target ? e.target.value : e.value;
   
    let d = {pm:  id , test: test, field: field, value : value , isTable : isTable , isPower: isPower , att: att}
    setLoading(true);
    ApiService.editTest(d)
        .then(res => {
          if (res.data.success ) {
            setDataLoaded(true);
            let array = data
            array.task_tests['access'].data  = res.data.data
            setData(array);
            // detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            // exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        })
  }


  const stepsDone = Object.keys(task.task_tests).filter((el) => el !== "clockin" && task.task_tests[el].data && task.task_tests[el].data.done);
  
  return (
    <React.Fragment>
      <form method="post" ref={exportForm} target={"_blank"} >
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
      <div className={"row "}>
        <div className={"col-md-12"}>
          <div className={"row ctable-row"}>
            <div className={"col-md-1 table-space-between"}>
              <span>{task.task_id}</span>
              {task.task_status ==="ongoing" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />}
              {!task.task_status && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} />}
              {task.task_status === "return" && <FontAwesomeIcon color="orange" icon={faUndo} />}
              {task.task_status === "closed" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheckDouble}  />}
              {task.task_status === "reject" && <FontAwesomeIcon color="#d9534f" icon={faTimes} />}
              {task.task_status === "correct" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheckSquare} />}
              {task.task_status ==="accept" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheckCircle} />}
             
              {/* {!task.task_status ? <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} /> : task.task_status === "return" ? <FontAwesomeIcon color="orange" icon={faUndo} /> : ( task.task_status === "reject" ? <FontAwesomeIcon color="rgb(48, 209, 88)" icon={fa} /> : <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />)} */}
            </div>

            <div className={"col-md-2"}>
              <a className={"site-link"} href={"/agents/" + task.task_agent_id}>{task.task_agent}</a>
            </div>

            <div className={"col-md-1"}>
              <a href={"/SSR/sites/detail/" + task.task_site_id} style={{ color: "inherit" }} className={"site-link"}>
                {task.task_site_code}
              </a>
            </div>

            <div className={"col-md-2"}>
              {task.owner}
            </div>

            <div className={"col-md-2"}>
              <span className={"task-plan-col"}>
                <span className={"line task-" + task.task_delay}>
                  <span className={"line-label"}>Créé</span>
                  <span className={"line-value"}>{task.task_created}</span>
                </span>
                <span className={"line"}>
                  <span className={"line-label"}>Plan</span>
                  <span className={"line-value"}>{task.task_plan}</span>
                </span>
              </span>
            </div>

            <div className={"col-md-2 task-tests-col"}>
              <span>{stepsDone.length + "/4"}</span>
              {/* {task.task_tests.anomalies && task.task_tests.anomalies.data && task.task_tests.anomalies.data.items.length > 0 ? <span className={"task-count-anomalies"}>{task.task_tests.anomalies.data.items.length} anomalies</span> : ""} */}
              {/* {task.task_tests.anomalies && task.task_tests.anomalies.data && task.task_tests.anomalies.data.items.filter(el => el.resolved).length > 0 ? <span className={"task-count-anomalies"}>{task.task_tests.anomalies.data.items.filter(el => el.resolved).length} résolues </span> : ""} */}
            </div>

            <div className={"col-md-2 task-details-col"}>
              {task.geo && task.geo.lat && (
                <a href={"https://www.google.com/maps/place/" + task.geo.lat + "," + task.geo.lng + "/@" + task.geo.lat + "," + task.geo.lng + ",15z"} target='_blank' className="task-action-btn" >
                  <FontAwesomeIcon icon={faMapMarker} />Localisation
                </a>
              )}

              <button onClick={() => selectTask(!showDetail)} className="task-action-btn" ><FontAwesomeIcon icon={isLoading ? faSpinner : faChevronDown} spin={isLoading} /> Info</button>

              <MoreInfoDialog title={"Plus"}>

                <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => exportPM()} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} /> Export</Item>
                </Restricted>

                <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => exportPMXLSX()} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} /> Export XLSX</Item>
                </Restricted>

                <Restricted scope={"pm"} permission={"delete_task"}>
                  <Item onClick={() => deleteTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash} /> Supprimer</Item>
                </Restricted>

                <Restricted scope={"pm"} permission={"close_task"}>
                  <Item onClick={() => closeTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faLock} />Cloturer</Item>
                </Restricted>

                <Restricted scope={"pm"} permission={"return_task"}>
                  <Item onClick={() => returnTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faUndo} />Retourner</Item>
                </Restricted>
                {((session.user.role === "manager" && session.user.owner == 'ooredoo')  || session.user.role === "admin") &&
                  <Item onClick={() => acceptTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faCheckDouble} />Accepter</Item>
                }
                {((session.user.role === "manager" && session.user.owner == 'ooredoo')  || session.user.role === "admin") &&
                  <Item onClick={() => rejectTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faTimes} />Rejeter</Item>
                }

                <Item onClick={() => addDataTask(task.task_id, task.data_m)}><FontAwesomeIcon className={"dialog-icon"} icon={faPlus} />Ajouter Données</Item>
                {/* <Item onClick={() => planifierTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faCheckDouble} />Plannifier</Item> */}

                {/* <Restricted scope={"pm"} permission={"read_task"}>
                  <Item onClick={() => exportPM(true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} />Export NOK</Item>
                </Restricted>
                  */}
                <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => showNewReplan(task.task_id)} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faReply} /> Réassigner </Item>
                </Restricted> 
              </MoreInfoDialog>
            </div>
          </div>

          {showDetail && dataLoaded && data.task_tests && (
            <div className={"animateRow row"}>
              <div className={"pm-wrapper col-md-12 row no-gutters"}>
                <div className={"col-md-3 pm-sidebar"}>
                  <ul>
                    {SSRTests.map(test => (
                      <li className={activeTest === test.key ? "active" : ""}>
                        <a onClick={e => selectTest(test.key)}>
                          <CheckOrTime task={task.task_tests[test.key]} />
                          {test.name} <FontAwesomeIcon icon={faAngleRight} size={22} />
                        </a>
                      </li>
                    ))}

                      <li >
                        <a onClick={e => selectTest('notesreturn')}>
                        <FontAwesomeIcon color="#adb5bd" icon={faStickyNote} size={22} />
                          Notes return<FontAwesomeIcon icon={faAngleRight} size={22} />
                        </a>
                      </li>
                  </ul>
                </div>

                <div className={"col-md-9 pm-content"}>
                  {SSRTests.map(test => data.task_tests[test.key] && data.task_tests[test.key].data && data.task_tests[test.key].data.done && activeTest === test.key && (
                    <div className={"pm-test-single"}>
                      <h5>{test.name} {data.task_tests[test.key].data.finished_at ? <b>{moment(data.task_tests[test.key].data.finished_at).format('YYYY-MM-DD HH:mm').toString()} <br /></b> : ""}</h5>
                      <RenderComponent component={test.component} data={data.task_tests[test.key].data}  geo = {task.geo } onchange={onchange} id={task.task_id} acc = {data.task_tests['access'].data} task = {task}/>
                    </div>
                  ))}

                    {activeTest === 'notesreturn' && <div className={"pm-test-single"}>
                      <h5> Notes return</h5>
                      <RenderComponent component={NotesReturn} data={data} geo = {task.geo } onchange={onchange} id={task.task_id}/>
                    </div>}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default TaskRow;
