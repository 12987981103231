import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import { faPen, faInfo, faPlay, faPowerOff, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const moment = require("moment");

const AgentRow = ({ user, selectUser, enableUser, disableUser, userDeleteBtn, ...props }) => {


    const {filters} = useSelector(state => ({filters: state.filters}));
    const version = (user.device_info && user.device_info["app-version"]) ? user.device_info["app-version"][0] : 0;


    const upToDate = filters.app_version === version

    
  return (
    <div className={"row ctable-row"}>
      <div className={"col-md-3"}>
        <div className={"agent-list-profile"}>
          <div className={"col-profile-ct"}>
            {/*<span className={"agent-list-photo task-status status-id-2"}>{user.account_type}</span>*/}

            <span className={"agent-list-username"}>
              <span>{user.username}</span>
              <span className={"status-id-1"}>
                <abbr>{user.cluster}</abbr>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className={"col-md-3"}>{user.manager_username ? user.manager_username.toUpperCase() : ""}</div>

      <div className={"col-md-2"}>{user.phone}</div>

      <div className={"col-md-2 site-plan-col-date"}>
        <span>
          {user.last_online
            ? moment(user.last_online)
                .format("YYYY-MM-DD HH:mm")
                .toString()
            : "Jamais"}
        </span>

        {user.device_info && user.device_info["app-version"] ? (
          <span className={"app-version-tag "+(upToDate ? "btn-success" : "btn-danger")}>{user.device_info["app-version"][0]}</span>
        ) : (
          ""
        )}
      </div>

      <div className={"col-md-1"}>{user.sites}</div>
      
      <div className={"col-md-1 task-details-col"}>
        <Link to={"/agents/" + user.agentid} className={"task-action-btn ml-2 "}>
          <FontAwesomeIcon icon={faInfo} /> Details
        </Link>
        {user.status !== "deleted" ? (
          <>
            <MoreInfoDialog>
              <Item onClick={() => selectUser(user)}>
                <FontAwesomeIcon className={"mr-2 "} icon={faPen} /> Modifier
              </Item>
              {user.status === "disabled" ? (
                <Item onClick={() => enableUser(user.userid)}>
                  <FontAwesomeIcon className={"mr-2 "} icon={faPlay} /> Activer
                </Item>
              ) : (
                <Item onClick={() => disableUser(user.userid)}>
                  <FontAwesomeIcon className={"mr-2 "} icon={faPowerOff} /> Désactiver
                </Item>
              )}
              <Item onClick={() => userDeleteBtn(user.userid)}>
                <FontAwesomeIcon className={"mr-2 "} icon={faTrash} /> Supprimer
              </Item>
            </MoreInfoDialog>
          </>
        ) : (
          <b>ACCOUNT DELETED</b>
        )}
      </div>
    </div>
  );
};

export default AgentRow;
