import React from "react";
import Constants from "./Constants";
import {ConvertDDToDMS} from "./Helpers"

const PhotoItem = ({photo, active, geo, ...props}) => {
    let url;
    let lng;
    let lat;
    if(typeof photo.cloud_asset_url !== 'undefined') {
        url = photo.cloud_asset_url;
    } else {
        url = Constants.apiURL+"/"+photo.url;
    }

    if(photo.location.length != 0){
        // lat = ConvertDDToDMS(photo.location.latitude, false) 
        // lng = ConvertDDToDMS(photo.location.longitude, true) 
        lat = photo.location.latitude
        lng = photo.location.longitude
    }
    console.log('this lat => '+ lat)


    if(true) {

        return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={url} target="_blank">
                    {photo.location.length != 0 &&  <div >
                            <p>Lat : {lat} ,  Lng :  {lng} </p>
                    </div> 
                    }
                    {/* <div >
                        { geo && <p>
                            Lat : {geo.lat} ,  Lng :  {geo.lng} 
                        </p>}
                    </div>  */}
                   
                <img src={url} alt="" />
                    
                {photo.comment ? (
                    <div className={"photo-comment"}>
                        <p>{photo.comment}</p>
                    </div>
                ) : <></>}
            </a>
        )
    } else {
        return <></>
    }
}

export default PhotoItem;