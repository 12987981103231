import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const PrintScreen = ({ data, active = true, ...props }) => {
  return (
    <div>
        <div className={"test-photos-wrapper"}>

          <table className={"table"}>
            <tr>
                <th>MW Insert attachments (print screen & backup)</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                   // key === 0
                   photo.index === 0
                    &&
                    <div>
                      <h6>Print screen, MW configuration</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index === 1
                    &&
                    <div>
                      <h6>Backup TN</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  
                </div>
                )}
            </tr>
            <tr>
                <th>  attachments Inserted</th>
                {data.photos && (
                <div className={"test-photos-wrapper"}>
                  {data.photos.map((photo, key) => (
                    photo.index === 2
                    &&
                    <div>
                      <h6>2G</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index === 3
                    &&
                    <div>
                      <h6>3G</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index === 4
                    &&
                    <div>
                      <h6>LTE</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}

                  {data.photos.map((photo, key) => (
                    photo.index === 5
                    &&
                    <div>
                      <h6>Trans</h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index === 6
                    &&
                    <div>
                      <h6> External alarm configuration </h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  {data.photos.map((photo, key) => (
                    photo.index === 7
                    &&
                    <div>
                      <h6> Sweep test done by site master </h6>
                      <PhotoItem photo={photo} active={active} />
                    </div>
                  ))}
                  
                </div>
                )}
            </tr>
    
          </table>

          </div>

          <h6>Test done and results</h6>

          <div className={"row col-md-6"}>
            <label>PSU's alignment with AC box : </label>
            <div className="col">
              {data.site.PSU_alignment_with_AC_box_status? <div className="row"> <b> Status </b>--> {data.site.PSU_alignment_with_AC_box_status} </div>: ''} 
              {data.site.PSU_alignment_with_AC_box_comment? <div className="row"> <b>Comment</b>--> {data.site.PSU_alignment_with_AC_box_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>RBS work with batteries only (AC off) : </label>
            <div className="col">
              {data.site.RBS_work_with_batteries_only_status? <div className="row"> <b> Status </b>--> {data.site.RBS_work_with_batteries_only_status} </div>: ''} 
              {data.site.RBS_work_with_batteries_only_comment? <div className="row"> <b>Comment</b>--> {data.site.RBS_work_with_batteries_only_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>All external alarms working : </label>
            <div className="col">
              {data.site.All_external_alarms_working_status? <div className="row"> <b> Status </b>--> {data.site.All_external_alarms_working_status} </div>: ''} 
              {data.site.All_external_alarms_working_comment? <div className="row"> <b>Comment</b>--> {data.site.All_external_alarms_working_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>Verifications of internal alarms in DU’s : </label>
            <div className="col">
              {data.site.Verifications_internal_alarms_DU_status? <div className="row"> <b> Status </b>--> {data.site.Verifications_internal_alarms_DU_status} </div>: ''} 
              {data.site.Verifications_internal_alarms_DU_comment? <div className="row"> <b>Comment</b>--> {data.site.Verifications_internal_alarms_DU_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>Air conditioner working : </label>
            <div className="col">
              {data.site.Air_conditioner_working_status? <div className="row"> <b> Status </b>--> {data.site.Air_conditioner_working_status} </div>: ''} 
              {data.site.Air_conditioner_working_comment? <div className="row"> <b>Comment</b>--> {data.site.Air_conditioner_working_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>RSL achieved : </label>
            <div className="col">
              {data.site.RSL_achieved_status? <div className="row"> <b> Status </b>--> {data.site.RSL_achieved_status} </div>: ''} 
              {data.site.RSL_achieved_comment? <div className="row"> <b>Comment</b>--> {data.site.RSL_achieved_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>Interference test : </label>
            <div className="col">
              {data.site.Interference_test_status? <div className="row"> <b> Status </b>--> {data.site.Interference_test_status} </div>: ''} 
              {data.site.Interference_test_comment? <div className="row"> <b>Comment</b>--> {data.site.Interference_test_comment} </div>: ''} 
            </div>
          </div>

          <div className={"row col-md-6"}>
            <label>XPD test achieved : </label>
            <div className="col">
              {data.site.XPD_test_achieved_status? <div className="row"> <b> Status </b>--> {data.site.XPD_test_achieved_status} </div>: ''} 
              {data.site.XPD_test_achieved_comment? <div className="row"> <b>Comment</b>--> {data.site.XPD_test_achieved_comment} </div>: ''} 
            </div>
          </div>


    </div>
    
  );
};

export default PrintScreen;
