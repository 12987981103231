import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { closeFlash, setFlash, setAuthAction, startLoading, doneLoading, loginUser } from "./../../Redux/actions/index";
import { Trans } from "react-i18next";

const mapStateToProps = state => {
  return {
    auth: state.session.auth,
    user: state.session.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    isLoading: p => dispatch(startLoading(p)),
    setFlash: f => dispatch(setFlash(f)),
    closeFlash: f => dispatch(closeFlash(f))
  };
}

class LoginBox extends Component {
  constructor(props) {
    super(props);

    this.props.doneLoading();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.passwordRef = React.createRef();

    this.state = {
      messageSpan: "",
      confirmation: false,
      form: {
        platform: "web",
        username: "",
        password: "",
        code: ""
      }
    };

    props.closeFlash();
  }

  handleInputChange(e) {
    let s = this.state;
    s.form[e.target.name] = e.target.value;
    this.setState({ ...s });
  }

  handleSubmit(e) {
    e.preventDefault();
    let that = this;

    /*if(this.state.form.password.length === 0)
			return this.passwordRef.current.focus();*/

    if (this.state.form.password.length < 4 || this.state.form.password.length < 4) {
      that.props.setFlash({
        active: true,
        message: "Enter valid username & password",
        classes: "warning",
        style: {}
      });

      return;
    }

    loginUser(this.state.form);
  }

  render() {
    //if (this.props.auth) return <Redirect to={"/delivery"} />;
    if (this.props.auth) return <Redirect to={"/ssr"} />;

    return (
      <div>
        <div className={"container-header"}>
          <Trans i18nKey="label_signin" />
        </div>

        <span className={"mb-4"}>{this.state.messageSpan}</span>
        <div className="col-md-5 login-box">
          <form onSubmit={this.handleSubmit} name="loginForm">
            <input type="hidden" name="hidden" value="aze" />

            <div className={"c-form-row form-group"}>
              <label className="label-style">
                <Trans i18nKey="user_username" />{" "}
              </label>
              <input
                disabled={this.state.confirmation}
                tabIndex="1"
                className="input-style"
                type="text"
                name="username"
                onChange={this.handleInputChange}
                value={this.state.form.username}
              />
              <br />
            </div>

            <div className={"c-form-row form-group"}>
              <label className="label-style">
                <Trans i18nKey="user_password" />{" "}
              </label>
              <input
                disabled={this.state.confirmation}
                ref={this.passwordRef}
                tabIndex="2"
                className="input-style"
                type="password"
                name="password"
                onChange={this.handleInputChange}
                value={this.state.form.password}
              />
            </div>

            {this.state.confirmation ? (
              <div className={"c-form-row form-group"}>
                <label className="label-style">
                  <Trans i18nKey="confirmation_code" />{" "}
                </label>
                <input
                  tabIndex="3"
                  className="input-style"
                  type="text"
                  name="code"
                  onChange={this.handleInputChange}
                  value={this.state.form.code}
                />
              </div>
            ) : (
              ""
            )}

            <div className={"c-form-row form-group"}>
              <button className={"btn btn-block btn-primary"} type="submit">
                <Trans i18nKey="label_signin" />
              </button>
            </div>

            {/* <div className={"c-form-row mt-4"}>
						<a href="#"><span className={"btn btn-info float-left"}><Trans i18nKey="label_joinus" /></span></a>
						<span className={"float-right"}><a href="#"><Trans i18nKey="password_recovery" /></a></span>
					</div> */}

            <div className="clear" />
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginBox);
