import React from "react";
import PhotoItem from "../../../../Utils/PhotoItem";


const TiltAfter = ({ data, active = true, ...props }) => {
    return (



        <div >
            <h6>Antenna Swap Report</h6>

            <div className={"row col-md-6"}>
                <label>Old Antenna Type : </label>
                <div className="col">
                    {data.site.Old_Antenna_Type_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.Old_Antenna_Type_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.Old_Antenna_Type_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>New Antenna Type : </label>
                <div className="col">
                    {data.site.New_Antenna_Type_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.New_Antenna_Type_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.New_Antenna_Type_c ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Old Antenna serial number : </label>
                <div className="col">
                    {data.site.Old_Antenna_serial_number_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.Old_Antenna_serial_number_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.Old_Antenna_serial_number_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>New Antenna serial number : </label>
                <div className="col">
                    {data.site.New_Antenna_serial_number_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.New_Antenna_serial_number_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.New_Antenna_serial_number_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Azimuth (°) : </label>
                <div className="col">
                    {data.site.Azimuth_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.Azimuth_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.Azimuth_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>HBA (m) from beginning of tower : </label>
                <div className="col">
                    {data.site.HBA_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.HBA_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.HBA_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Mechanical Tilt Before : </label>
                <div className="col">
                    {data.site.Before_Mechanical_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.Before_Mechanical_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.Before_Mechanical_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Mechanical Tilt After : </label>
                <div className="col">
                    {data.site.After_Mechanical_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.After_Mechanical_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.After_Mechanical_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Electrical tilt 900M Before : </label>
                <div className="col">
                    {data.site.Before_Electrical_900_A ? <div className="row"> <b>Cell A </b>--> {data.site.Azimuth_A} </div> : ''}
                    {data.site.Before_Electrical_900_B ? <div className="row"> <b>Cell B </b>--> {data.site.Azimuth_B} </div> : ''}
                    {data.site.Before_Electrical_900_C ? <div className="row"> <b>Cell C </b>--> {data.site.Azimuth_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Electrical tilt 900 After : </label>
                <div className="col">
                    {data.site.After_Electrical_900_A ? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_900_A} </div> : ''}
                    {data.site.After_Electrical_900_B ? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_900_B} </div> : ''}
                    {data.site.After_Electrical_900_C ? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_900_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Electrical tilt 1800 Before : </label>
                <div className="col">
                    {data.site.Before_Electrical_1800_A ? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_1800_A} </div> : ''}
                    {data.site.Before_Electrical_1800_B ? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_1800_B} </div> : ''}
                    {data.site.Before_Electrical_1800_C ? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_1800_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Electrical tilt 1800 After : </label>
                <div className="col">
                    {data.site.After_Electrical_1800_A ? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_1800_A} </div> : ''}
                    {data.site.After_Electrical_1800_B ? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_1800_B} </div> : ''}
                    {data.site.After_Electrical_1800_C ? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_1800_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Electrical tilt 3G 2100M Before : </label>
                <div className="col">
                    {data.site.Before_Electrical_3G_2100_A ? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_3G_2100_A} </div> : ''}
                    {data.site.Before_Electrical_3G_2100_B ? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_3G_2100_B} </div> : ''}
                    {data.site.Before_Electrical_3G_2100_C ? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_3G_2100_C} </div> : ''}
                </div>
            </div>

            <div className={"row col-md-6"}>
                <label>Electrical tilt 3G 2100M After: </label>
                <div className="col">
                    {data.site.After_Electrical_3G_2100_A ? <div className="row"> <b>Cell A </b>--> {data.site.Electrical_LTE_A} </div> : ''}
                    {data.site.After_Electrical_3G_2100_B ? <div className="row"> <b>Cell B </b>--> {data.site.Electrical_LTE_B} </div> : ''}
                    {data.site.After_Electrical_3G_2100_C ? <div className="row"> <b>Cell C </b>--> {data.site.Electrical_LTE_C} </div> : ''}
                </div>
            </div>

            <div className={"test-photos-wrapper"}>
                <table className={"table"}>

                    <tr>
                        <th>- GPS Picture</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                  //  key === 0
                                  photo.index=== 0
                                    &&
                                    <div>
                                        <h6>CELL A</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 1
                                    &&
                                    <div>
                                        <h6>Before Swap</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- SITE CLEANUP</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 1
                                    &&
                                    <div>
                                        <h6>Before Swap</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 2
                                    &&
                                    <div>
                                        <h6>After Swap</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 4
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Site General View</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 3
                                    &&
                                    <div>
                                        <h6>Before Swap</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 4
                                    &&
                                    <div>
                                        <h6>After Swap</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th>- Panoramic view</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 5
                                    &&
                                    <div>
                                        <h6>Panoramic view</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 1
                                    &&
                                    <div>
                                        <h6>Before Swap</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    key === 2
                                    &&
                                    <div>
                                        <h6>CELL B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>

                    {/* //////////// Sector A Antenna Swap Start */}
                    <tr>
                        <th>Sector A Antenna Swap</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 6
                                    &&
                                    <div>
                                        <h6>Before swap azimuth</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 7
                                    &&
                                    <div>
                                        <h6>After swap azimuth</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 8
                                    &&
                                    <div>
                                        <h6>Before swap Mechanical Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 9
                                    &&
                                    <div>
                                        <h6>After swap Mechanical Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 10
                                    &&
                                    <div>
                                        <h6>Before swap Mechanical Tilt(0)</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 11
                                    &&
                                    <div>
                                        <h6>After swap Mechanical Tilt(0)</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 12
                                    &&
                                    <div>
                                        <h6>Before swap 900M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 13
                                    &&
                                    <div>
                                        <h6>After swap 900M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 14
                                    &&
                                    <div>
                                        <h6>Before swap 1800M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 15
                                    &&
                                    <div>
                                        <h6>After swap 1800M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 16
                                    &&
                                    <div>
                                        <h6>Before swap 2100M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 17
                                    &&
                                    <div>
                                        <h6>After swap 2100M Electric Tilt()</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>
                    {/* //////////// Sector A Antenna Swap End */}

                    {/* //////////// Sector B Antenna Swap Start */}
                    <tr>
                        <th>Sector B Antenna Swap</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 18
                                    &&
                                    <div>
                                        <h6>Before swap azimuth</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 19
                                    &&
                                    <div>
                                        <h6>After swap azimuth</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 20
                                    &&
                                    <div>
                                        <h6>Before swap Mechanical Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 21
                                    &&
                                    <div>
                                        <h6>After swap Mechanical Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 22
                                    &&
                                    <div>
                                        <h6>Before swap Mechanical Tilt(0)</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 23
                                    &&
                                    <div>
                                        <h6>After swap Mechanical Tilt(0)</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 24
                                    &&
                                    <div>
                                        <h6>Before swap 900M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 25
                                    &&
                                    <div>
                                        <h6>After swap 900M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 26
                                    &&
                                    <div>
                                        <h6>Before swap 1800M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 27
                                    &&
                                    <div>
                                        <h6>After swap 1800M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 28
                                    &&
                                    <div>
                                        <h6>Before swap 2100M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 29
                                    &&
                                    <div>
                                        <h6>After swap 2100M Electric Tilt()</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>
                    {/* //////////// Sector B Antenna Swap End */}

                    {/* //////////// Sector C Antenna Swap Start */}
                    <tr>
                        <th>Sector C Antenna Swap</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 30
                                    &&
                                    <div>
                                        <h6>Before swap azimuth</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 31
                                    &&
                                    <div>
                                        <h6>After swap azimuth</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 32
                                    &&
                                    <div>
                                        <h6>Before swap Mechanical Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 33
                                    &&
                                    <div>
                                        <h6>After swap Mechanical Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 34
                                    &&
                                    <div>
                                        <h6>Before swap Mechanical Tilt(0)</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 35
                                    &&
                                    <div>
                                        <h6>After swap Mechanical Tilt(0)</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 36
                                    &&
                                    <div>
                                        <h6>Before swap 900M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 37
                                    &&
                                    <div>
                                        <h6>After swap 900M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 38
                                    &&
                                    <div>
                                        <h6>Before swap 1800M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 39
                                    &&
                                    <div>
                                        <h6>After swap 1800M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 40
                                    &&
                                    <div>
                                        <h6>Before swap 2100M Electric Tilt</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 41
                                    &&
                                    <div>
                                        <h6>After swap 2100M Electric Tilt()</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>
                    {/* //////////// Sector C Antenna Swap End */}

                    {/* //////////// Antenna Type and SN End */}
                    <tr>
                        <th>Antenna Type and SN</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 42
                                    &&
                                    <div>
                                        <h6>Sector A Before</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 43
                                    &&
                                    <div>
                                        <h6>Sector A After</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 44
                                    &&
                                    <div>
                                        <h6>Sector B Before</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>

                    <tr>
                        <th> </th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 45
                                    &&
                                    <div>
                                        <h6>Sector B After</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 46
                                    &&
                                    <div>
                                        <h6>Sector C Before</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 47
                                    &&
                                    <div>
                                        <h6>Sector C After</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>
                    {/* //////////// Antenna Type and SN End */}

                    {/* //////////// Site Access Start */}
                    <tr>
                        <th>Site Access</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 48
                                    &&
                                    <div>
                                        <h6>Photo 1</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 49
                                    &&
                                    <div>
                                        <h6>Photo 2</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {/* {data.photos.map((photo, key) => (
                                    key === 44
                                    &&
                                    <div>
                                        <h6>Sector B Before</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))} */}
                            </div>
                        )}
                    </tr>
                    {/* //////////// Site Access End */}

                    {/* //////////// Antenna Pole Start */}
                    <tr>
                        <th>Antenna Pole</th>
                        {data.photos && (
                            <div className={"test-photos-wrapper"}>
                                {data.photos.map((photo, key) => (
                                    photo.index === 50
                                    &&
                                    <div>
                                        <h6>Sector A</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 51
                                    &&
                                    <div>
                                        <h6>Sector B</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                                {data.photos.map((photo, key) => (
                                    photo.index === 52
                                    &&
                                    <div>
                                        <h6>Sector C</h6>
                                        <PhotoItem photo={photo} active={active} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </tr>
                    {/* //////////// Antenna Pole End */}


                </table>

            </div>


        </div>
    );
};

export default TiltAfter;
